import React, {Component, useState} from 'react';
import * as Define from '../constants/define';
import * as Validate from './validation';
import routes from '../routers';
import TAG_DEFINE from '../constants/common';
import $ from 'jquery';
import queryString from "query-string";
import {message, notification} from 'antd';
import {get, merge} from 'lodash';
import {FILES} from '../constants/define'
import {parseListRequestParams} from "./commonUtils";
import {detailColumn} from "../data/mapping/Response/TaxSaleResponse";
import * as Types from "../data/types/Template";
import * as TypesAuth from "../data/types/Auth";
// import i18n from "../constants/initLng";

import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';

export const downloadFile = (data, filename, blobOptions) => {
    const url = window.URL.createObjectURL(new Blob([data], blobOptions));
    const newLink = document.createElement('a');
    newLink.href = url;
    newLink.setAttribute('download', filename); //or any other extension
    document.body.appendChild(newLink);
    newLink.click();
    window.URL.revokeObjectURL(url);
    newLink.remove();
    return "ok";
};

export const checkParamSort = (valueFilter, stateParamsSort) => {
    let val = removeObjectNull(valueFilter);
    let paramsSort = removeObjectNull(stateParamsSort);
    paramsSort = {...paramsSort, ...val};
    return paramsSort;
};

export const removeElementObject = (state, field) => {
    let result = {};
    Object.keys(state).map((i) => {
        if (i !== field) {
            result = merge(result, {[i]: state[i]})
        }
    });
    return result;
};

export const removeArrayElementObject = (state, arrayField) => {
    let result = {};
    if (arrayField) {
        Object.keys(state).map((i) => {
            if (!arrayField.includes(i)) {
                result = {...result, [i]: state[i]}
            }
        });
    }
    return result;
};

export const removeObjectNull = (state, check = false) => {
    if (state) {
        for (var propName in state) {
            if (state[propName] === null || state[propName] === undefined) {
                delete state[propName];
            }
        }
    }
    return state;
};

export const removeElementFromArray = (items = [], field = "") => {
    let result = [];
    if (items.length > 0) {
        const index = items.indexOf(field);
        if (index > -1) {
            items.splice(index, 1);
        }
    }
    return result;
};

export const removeElementNull = (state) => {
    let result = [];
    if (state) {
        Object.keys(state).map((i) => {
            if (state[i]) {
                result.push({[i]: state[i]});
            }
        });
    }
    return result;
};

export const striptags = (val) => {
    val = val.replace(/(<\/?[\S][^>]*>)|([^a-zA-Z0-9@])/ig, "");
    return val;
};

export const showMoney = (val) => {
    var num = 0;
    if (val === "" || val === 0) {
        num = 0;
    } else {
        if (val.length > 6 && val.length < 10) {
            num = parseInt(val.replace(/,/g, '')).toString().replace(/\B(?=(\d{6})+(?!\d))/g, " ");
            num = num.split(" ")[0] + " Triệu";
        } else if (val.length > 9) {
            num = (parseInt(val.replace(/,/g, '')).toString().replace(/\B(?=(\d{8})+(?!\d))/g, " ")).split(" ")[0].toString().replace(/\B(?=(\d{1})+(?!\d))/g, ",");
            if (num.split(",")[1] > 0) {
                num = num + " Tỷ";
            } else {
                num = num.split(",")[0] + " Tỷ";
            }
        } else {
            num = parseInt(val.replace(/,/g, '')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    return num;
};

export const formatMoney = (val) => {
    let num = 0;
    let oldVal = val ? val.toString() : "";
    if (oldVal === "" || oldVal === 0) {
        num = 0;
    } else {
        num = isNaN(parseInt(oldVal.replace(/,/g, '')).toString()) ? 0 : parseInt(oldVal.replace(/,/g, ''));
        // num = parseInt(oldVal.replace(/,/g, ''));
        num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    // let newVal = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num;
};
export const convertFormatMoneyToNumber = (val) => {
    return parseInt(val.replace(/,|_/g, ''));
};

export const formatNumber = (val) => {
    let num = 0;
    let oldVal = val ? val.toString() : "";
    if (oldVal === "" || oldVal === 0) {
        num = 0;
    } else {
        num = isNaN(parseInt(oldVal.replace(/,/g, '')).toString()) ? 0 : parseInt(oldVal.replace(/,/g, ''));
        // num = parseInt(oldVal.replace(/,/g, ''));
        num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
    }
    // let newVal = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
    return num;
};

export const formatFloatNumber = (val) => {
    let num = 0;
    let oldVal = val ? val.toString() : "";
    if (oldVal === "" || oldVal === 0) {
        num = "0";
    } else {
        let newNum = /^[0-9.-]+$/i.test(oldVal) ? oldVal : ((oldVal.length > 1) ? oldVal.replace(/[^\d.\-]/g, "") : "0");
        num = newNum.length > 1 ? newNum[0] === "0" ? newNum.substring(1) : newNum : newNum;
    }
    return num;
};

export const formatPhone = (val) => {
    let num = 0;
    let oldVal = val ? val.toString() : "";
    if (oldVal === "" || oldVal === 0) {
        num = 0;
    } else {
        num = oldVal.toString().replace(/([\D])/g, "");
    }
    return num;
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTimeStamp = (plusDay = 0) => {
    var newDay = new Date();
    newDay.setDate(newDay.getDate() + plusDay);
    // const date = new Date();
    const year = newDay.getFullYear();
    let month = newDay.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    let day = newDay.getDate();
    if (day < 10) {
        day = '0' + day;
    }
    let hour = newDay.getHours();
    if (hour < 10) {
        hour = '0' + hour;
    }
    let minute = newDay.getMinutes();
    if (minute < 10) {
        minute = '0' + minute;
    }
    let second = newDay.getSeconds();
    if (second < 10) {
        second = '0' + second;
    }
    return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
};

export const convertTime = (ms) => {
    var d, h, m, s;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    return `${d} - Ngày : ${h} - Giờ : ${m} - Phút : ${s} - Giây`;
};

export const sleepFunction = ms => new Promise(resolve => setTimeout(resolve, ms));

let timer;
export const lastRequest = ms => new Promise(resolve => {
    clearTimeout(timer);
    timer = setTimeout(resolve, ms);
});

export const validateProduct = (val) => {
    var arr = {
        'name': "Tên sản phẩm",
        'slug': "Tên sản phẩm",
        'cat_id': "Danh mục",
        'supplier_id': "Nhà quản lý",
        'staff_id': "Nhân viên",
    };
    var find = "";
    Object.keys(arr).filter(i => {
        if (i === val) {
            find = arr[i]
        }
        ;
    });
    return find;
};


export const messageValidator = (check, field, message) => {
    let result;
    if (check) {
        result = {
            [field]: {
                classError: "hms-error-form",
                messageError: message,
            }
        }
    } else {
        result = {};
    }
    return result;
};

export const checkValidate = (data, state, arrayValidate, valueCheck, valueValidate) => {
    let validate = state;
    let result;
    if (Object.keys(arrayValidate).length > 0) {
        Object.keys(arrayValidate).map(i => (
            validate = {
                ...validate,
                [i]: messageValidator(Validate[valueCheck](data[i] ? data[i] : "", valueValidate && valueValidate[i]), i, arrayValidate[i])[i]
            }
        ));
    }
    Object.keys(validate).filter(i => {
        if ((validate[i] && Object.keys(arrayValidate).includes(i))) {
            result = {...result, [i]: validate[i]}
        }
    });
    return result ? result : {};
};

export const multipleValidate = (data, ruleCheck, maxmin) => {
    $(".form-control").removeClass('isValidation');
    $(".form-control input").removeClass('isValidation');
    $(".hms-form-control > div").removeClass('isValidation');
    $(".hms-input").removeClass('isValidation');
    $(".ant-select-selector").removeClass('isValidation');
    if (ruleCheck) {
        let resultCheck = {};
        if (maxmin) {
            Object.keys(ruleCheck).map(i => Object.keys(checkValidate(data, data, ruleCheck[i], i, maxmin[i])).length > 0 && (
                resultCheck = checkValidate(data, data, ruleCheck[i], i, maxmin[i])
            ));
            // return resultCheck;
        } else {
            Object.keys(ruleCheck).map(i => Object.keys(checkValidate(data, data, ruleCheck[i], i)).length > 0 && (
                resultCheck = checkValidate(data, data, ruleCheck[i], i)
            ));
        }
        if (Object.keys(resultCheck).length > 0) {
            Object.keys(resultCheck).map(i => {
                $(".hms-input").has("input[name=" + i + "]").addClass('isValidation');
                $("input[name=" + i + "]").addClass('isValidation');
                $("select[name=" + i + "]").addClass('isValidation');
                $("." + i + " > div").addClass('isValidation');
                $(".ant-select").filter("[name=" + i + "]").children(".ant-select-selector").addClass('isValidation');
            })
        }
        return resultCheck;
    } else {
        return {};
    }
};

export const reloadValidation = (validation, field) => {
    let result = {};
    Object.keys(validation).map((i) => {
        if (i !== field) {
            $("input[name=" + i + "]").addClass('isValidation');
            $("select[name=" + i + "]").addClass('isValidation');
            $("." + i + " > div").addClass('isValidation');
            $(".hms-input").has("input[name=" + i + "]").addClass('isValidation');
            $(".ant-select").filter("[name=" + i + "]").children(".ant-select-selector").addClass('isValidation');
            result = {...result, [i]: validation[i]}
        } else {
            $("input[name=" + i + "]").removeClass('isValidation');
            $("select[name=" + i + "]").removeClass('isValidation');
            $("." + i + " > div").removeClass("isValidation");
            $(".hms-input").has("input[name=" + i + "]").removeClass('isValidation');
            $(".ant-select").filter("[name=" + i + "]").children(".ant-select-selector").removeClass('isValidation');
        }
    });
    return result;
};

export const classValidation = (data, validation, tag) => {
    if (tag) {
        if (Validate[tag](data && data) || validation) {
            return 'hms-error-form';
        } else {
            return "";
        }
    } else {
        return "";
    }

};

export const convertUTCDateToLocalDate = (date) => {
    let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
};

export const formatShowDate = (date) => {
    return convertUTCDateToLocalDate(new Date(date)).toLocaleDateString('vi-VN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).split(',')[1]
};

export const formatShowDateTime = (date) => {
    return convertUTCDateToLocalDate(new Date(date)).toLocaleDateString('vi-VN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).split(',')[1] + " " + convertUTCDateToLocalDate(new Date(date)).toLocaleDateString('vi-VN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).split(',')[0];
};

export const formatShowDateTimeNonConvert = (date) => {
    return (new Date(date)).toLocaleDateString('vi-VN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).split(',')[1] + " " + (new Date(date)).toLocaleDateString('vi-VN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).split(',')[0];
};

export const findParentRoute = (groupName, props, objReplace = {}) => {
    let url = props.match.path;
    let itemRoute = FindIteminArrayRecursive(routes, url);
    let pushUrl = "";
    routes.map(i => {
        if (itemRoute.item.tagSub) {
            if (i.tagSub === itemRoute.item.tagSub) {
                i.children && i.children.length > 0 && i.children.map(i2 => {
                    if (i2.group === itemRoute.item.group) {
                        pushUrl = i2.path
                    }
                })
            }
        } else {
            if (i.group === groupName) {
                pushUrl = i.path
            }
        }
    });
    // ADD param parent
    Object.keys(objReplace || {}).forEach(key => {
        pushUrl = pushUrl.replace(key, objReplace[key]);
    })
    return props.history.push(pushUrl);
};

export const getValueForm = (e) => {
    let target = e.target;
    let name;
    let value;
    let newVal;
    if (e.target) {
        name = target.name;
        value = target.value;
        if (target.type === "checkbox") {
            value = target.checked
        }
    } else {
        name = e.name;
        value = e.value;
    }
    newVal = {name: name, value: typeof value === 'string' ? value.trimLeft() : value};
    return newVal;
};

export const checkUndefined = (val) => {
    if (Array.isArray(val)) {
        return val.length > 0 ? val : []
    } else {
        return val || val !== undefined || val !== "" || val !== null ? val : "";
    }
};

export const columnsTable = (props) => {
    let obj = [];
    const titleGenerate = (params) => {
        if (props.children) {
            let title = props.itemName.split('.');
            let newTitle = "";
            title.map((i, k) => {
                if (k === 0) {
                    newTitle = TAG_DEFINE.GENERAL.ITEM[i.toUpperCase()]
                } else {
                    newTitle = newTitle[i]
                }
            });
            return newTitle[params];
        } else {
            return TAG_DEFINE.GENERAL.ITEM[(props.itemName).toUpperCase()][params];
        }
    };
    if (Object.keys(props).length > 0) {
        if (props.titleTable.length > 0) {
            props.titleTable.map((i, k) => {
                if (props.component && props.component[i]) {
                    obj.push({
                        title: titleGenerate(i),
                        // title: i18n.t(`item.${props.itemName}.${i}`),
                        dataIndex: i,
                        key: k,
                        ...props.component[i],
                    })
                } else {
                    obj.push({
                        title: titleGenerate(i),
                        // title: i18n.t(`item.${props.itemName}.${i}`),
                        dataIndex: i,
                        key: k,
                    })
                }
            });
        }
    }
    return obj;
};

export const customEditorTable = (props, editField = []) => {
    let obj = [];
    const titleGenerate = (params) => {
        if (props.children) {
            let title = props.itemName.split('.');
            let newTitle = "";
            title.map((i, k) => {
                if (k === 0) {
                    newTitle = TAG_DEFINE.GENERAL.ITEM[i.toUpperCase()]?.EDITOR_COLUMN
                } else {
                    newTitle = newTitle[i]
                }
            });
            return newTitle[params];
        } else {
            return TAG_DEFINE.GENERAL.ITEM[(props.itemName).toUpperCase()]?.EDITOR_COLUMN[params];
        }
    };
    if (Object.keys(props).length > 0) {
        if (props.titleTable.length > 0) {
            props.titleTable.map((i, k) => {
                if (props.component && props.component[i]) {
                    if (editField.includes(i)) {
                        obj.push({
                            title: titleGenerate(i),
                            // title: i18n.t(`item.${props.itemName}.${i}`),
                            dataIndex: i,
                            key: k,
                            ...props.component[i],
                            onCell: (record) => ({
                                record,
                                editable: 1,
                                dataindex: i,
                                key: k,
                                title: titleGenerate(i),
                                // handleSave: handleSave,
                            }),
                        })
                    } else {
                        obj.push({
                            title: titleGenerate(i),
                            // title: i18n.t(`item.${props.itemName}.${i}`),
                            dataIndex: i,
                            key: k,
                            ...props.component[i],
                        })
                    }
                } else {
                    if (editField.includes(i)) {
                        obj.push({
                            title: titleGenerate(i),
                            // title: i18n.t(`item.${props.itemName}.${i}`),
                            dataIndex: i,
                            key: k,
                            onCell: (record) => ({
                                record,
                                editable: 1,
                                dataindex: i,
                                key: k,
                                title: titleGenerate(i),
                                // handleSave: handleSave,
                            }),
                        })
                    } else {
                        obj.push({
                            title: titleGenerate(i),
                            // title: i18n.t(`item.${props.itemName}.${i}`),
                            dataIndex: i,
                            key: k,
                        })
                    }
                }
            });
        }
    }
    return obj;
};

export const arrayRemoveItem = (array, itemRemove) => {
    return array.filter(function (ele) {
        return ele !== itemRemove;
    });
};

export const getTitleTable = (model, arrayTitle, arrayItemRemove = []) => {
    let newArrayTitle = model.concat(arrayTitle);
    if (arrayItemRemove.length > 0) {
        let titleTable = newArrayTitle;
        arrayItemRemove.map(i => {
            titleTable = titleTable.filter((ele) => ele !== i);
        });
        return titleTable;
    } else {
        return newArrayTitle;
    }
};

export const getDataSource = (data, model) => {
    const newData = (data || []).map(data => {
        data.children = undefined;
        return data
    });
    let dataSource = [];
    let listItemData = {};
    model.map(i => listItemData = {...listItemData, [i]: ""});
    if (newData.length > 0) {
        newData.map((i, k) => {
            let itemData;
            Object.keys(listItemData).map(i2 => {
                itemData = {...itemData, [i2]: i[i2]};
            });
            dataSource.push(itemData)
        })
    }
    return dataSource;
};

export const customOptionSelect = (data, params) => {
    let newData = [];
    if (data && data.length > 0 && params) {
        data.map((i, k) => {
            let object = {};
            params.map((i2, k) => {
                if (k === 0) {
                    object = {name: i[i2]}
                } else if (k === 1) {
                    object = {...object, value: i[i2]}
                } else {
                    object = {...object, [i2]: i[i2]};
                }
            });
            newData.push(object)
        })
    }
    return newData;
};

export const formatValidation = (s, f) => {
    if (s) {
        return capitalizeFirstLetter(s.replace('%s', f).toLowerCase())
    }
};

export const convertStringToNumber = (string, name, arrayField = []) => {
    if (arrayField.includes(name)) {
        return formatNumber(string);
    } else {
        return string
    }
};

export const genaratePaginateFilterSort = (pagination, filters, sorter, skipQueryParse = false) => {
    let paramsFilter = skipQueryParse ? filters : queryString.parse(queryString.stringify(filters));
    paramsFilter = Object.keys(paramsFilter).length > 0 ? paramsFilter : undefined;
    let newFilters = {
        ...paramsFilter,
        page_index: pagination ? pagination.current : {},
        page_size: pagination ? pagination.pageSize : undefined,
        order_by: sorter.field,
        order_by_desc: sorter.field ? sorter.order === 'descend' : undefined
    };
    newFilters = removeObjectNull(newFilters);
    return newFilters;
};

function getArrayOfChildren(arr, existingChildren) {
    arr.forEach(o => {
        existingChildren.push(o.path);
        o.children && getArrayOfChildren(o.children, existingChildren);
    });
    return existingChildren;
}

let output = {};
export const FindIteminArrayRecursive = (arr, value) => {
    arr.forEach(o => {
        // let flag = false;
        if (o.path) {
            if (o.path === value) {
                output.item = o;
                output.childs = (o.children && getArrayOfChildren(o.children, [])) || [];
            } else if (o.children && o.children.length) {
                output = FindIteminArrayRecursive(o.children, value);
            }
        }
    });
    return output;
};

export const collectChildrenParentToArray = (values) => {
    let result = values.map(value =>
        (value.sub_menu || []).map(child => Object.assign({group_name: value.group}, child))
    ).reduce((l, n) => l.concat(n), []);
    return result;
}

export const getPermissionActions = (route) => {
    const profile = localStorage.getItem('hn_pf');
    const permissions = profile && JSON.parse(profile).permissions ? JSON.parse(profile).permissions : [];
    let actionResult = {
        allowCreate: permissions.includes(`${route}.create`),
        allowUpdate: permissions.includes(`${route}.update`),
        allowDelete: permissions.includes(`${route}.delete`),
        allowApprove: permissions.includes(`${route}.approve`),
        allowResendEmail: permissions.includes(`${route}.resendEmail`),
        allowSaleOrder: permissions.includes(`${route}.saleOrder`),
        allowUseVoucher: permissions.includes(`${route}.useVoucher`),
    };
    return actionResult;
};

export const getFilenameFromUrl = (url) => url ? url.split('/').pop().split('#').shift().split('?').shift() : undefined;

export const getPathWithParams = (path = '', params = {}) => {
    Object.keys(params).forEach(p => {
        const searchStrParam = `\/:${p}`;
        if (searchStrParam.length > 2) {
            path = path.replace(searchStrParam, `\/${params[p]}`);
        } else {
            path = `${path}${queryString.stringify(params).length > 0 ? "/" + queryString.stringify(params) : ""}`
        }
    });
    return path;
};

export const getPathWithParamsDetail = (path = '', params = {}) => {
    Object.keys(params).forEach(p => {
        const searchStrParam = `\/:${p}`;
        path = path.replace(searchStrParam, `\/${params[p]}`);
    })
    return path;
}

/**
 * Wrapper every saga process async function* to by pass try catch block.
 * @param {function} [saga] - saga process async function*.
 * @param {function} [errorHandle] - error handle async function*.
 */
export function sagaWrapper(saga, errorHandle, customMessageErrors = "") {
    return function* (action) {
        try {
            let listAction = [Types.TEMPLATE_GENERAL_ACTION, TypesAuth.PASSWORD_REQUEST_ACTION, TypesAuth.PASSWORD_RESET_ACTION]
            if (listAction.includes(action.type)) {
                yield saga(action);
            } else {
                yield saga(action);
                // yield put({type: Types.TEMPLATE_GENERAL_ACTION})
            }
        } catch (e) {
            yield errorHandle(e)
        }
    };
}

/**
 * Handle displaying errors in saga processes.
 * @param {function} callback - callback asyn function* after show error message.
 * @param {string} itemStringKey - item string key to get item error message: (Ex: hotel, tour, order, group,...).
 */
export function sagaErrorHandler(callback) {
    return function* (e) {
        if (e?.customMessageErrors) {
            message.error(e?.customMessageErrors, 3)
        } else if (e.status === 403) {
            message.error("Bạn không thể vào trang này!", true);
        } else if (e.status === 400 && e?.errors?.file) {
            message.error("Tập tin đính kèm không đúng định dạng");
        } else if (e.status === 200) {
            message.error(e.message, 3);
        } else {
            message.error("System Error. Please try again later!", true);
        }
        console.log(e);
        if (callback) {
            yield callback(e);
        }
    };
}

/**
 * Display success notifications in saga processes
 * @param {Object} [action] - saga action object.
 * @param {string} [message] - message to display.
 * @param {string} description - description to display as sub text bellow the message.
 */
export function sagaSuccessMessage(action, message, description) {
    return notification.success({message, description})
}

export function vietnameseStringToUnicode(vnString) {
    vnString = vnString.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    vnString = vnString.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    vnString = vnString.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    vnString = vnString.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    vnString = vnString.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    vnString = vnString.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    vnString = vnString.replace(/đ/gi, 'd');
    return vnString;
}

export function stringToUrlSlug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    let slug = str.toLowerCase();
    //Đổi ký tự có dấu thành không dấu
    slug = vietnameseStringToUnicode(slug);
    //Xóa các ký tự đặt biệt
    slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
    //Đổi khoảng trắng thành ký tự gạch ngang
    slug = slug.replace(/ /gi, "-");
    //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
    //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
    slug = slug.replace(/\-\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-\-/gi, '-');
    slug = slug.replace(/\-\-\-/gi, '-');
    slug = slug.replace(/\-\-/gi, '-');
    //Xóa các ký tự gạch ngang ở đầu và cuối
    slug = '@' + slug + '@';
    slug = slug.replace(/\@\-|\-\@|\@/gi, '');
    return slug.replace(' ', '');
};

export function getListItem(data, model) {
    // console.log(data)
    let item = [];
    if (data.length > 0) {
        data.map(async i => {
            let data = new model(i).get();
            item.push(data)
        })
    }
    return item
};

export const getThumbUrl = (data = []) => {
    let array = [];
    let fileValidation = ['jpeg', 'jpg', 'png'];
    if (data.length > 0) {
        data.map(i => {
            if (i) {
                // if(fileValidation.includes(i.split(".")[(i.split(".") || []).length -1])){
                //     array.push(`${FILES.CDN_IMG_DEFAULT}/${FILES.SIZE_THUMB}/${i}`)
                // }else{
                //     array.push(Images.defaultFile.default)
                // }
                array.push(`${FILES.CDN_IMG_DEFAULT}/${FILES.SIZE_THUMB}/${i}`)
            }
        });
    }
    return array;
};

export const getFilesUrl = (data = []) => {
    let array = [];
    let fileValidation = ['jpeg', 'jpg', 'png'];
    if (data.length > 0) {
        data.map(i => {
            if (i) {
                if (fileValidation.includes(i.split(".")[(i.split(".") || []).length - 1])) {
                    array.push(`${FILES.CDN_IMG_DEFAULT}/${FILES.SIZE_THUMB}/${i}`)
                } else {
                    array.push(`${FILES.CDN_IMG_DEFAULT}/${i}`)
                }
            }
        });
    }
    return array;
};

export const formatInt = (number, defaultNum = 0) => isNaN(parseInt(number)) ? (typeof number === "boolean") ? (number === true) ? 1 : 0 : number ? 1 : defaultNum : parseInt(number);

export const setParamsUrlFilter = (path, params) => {
    let queryUrl = "";
    if (Object.keys(params || {}).length > 0) {
        params = parseListRequestParams(params);
        queryUrl = `${path}?${queryString.stringify(params, {arrayFormat: 'comma'})}`
    } else {
        queryUrl = path
    }
    return queryUrl
}

export const removeObjectNullFull = (state, notIn = []) => {
    if (state) {
        for (var propName in state) {
            if (state[propName] === null || state[propName] === undefined || state[propName] === "" || (Array.isArray(state[propName]) && (state[propName] || []).length < 1) && (typeof state[propName] !== "number")) {
                delete state[propName];
            }
        }
    }
    return state;
};

export const convertParamsToSnake = (str, condition) => {
    if (!condition) {
        return str.split(/(?=[A-Z])/).join('_').toLowerCase()
    }
    if (condition.toLowerCase() === "blank") {
        return ((str || "").toLowerCase()).replaceAll(" ", "_")
    }
};

export const generateRowItemDetail = (arrayItems = [], detailColumn, fieldIncludes = []) => {
    let newResult = [];
    if (Object.keys(arrayItems).length > 0) {
        Object.keys(arrayItems).map((i, k) => {
            if (!fieldIncludes.includes(i)) {
                newResult.push({
                    key: k,
                    title: detailColumn[i],
                    value: arrayItems[i],
                    param: i
                })
            }
        })
    }
    return newResult;
}

export const convertBooleanToInt = val => {
    return val ? (typeof val === "boolean") ? (val === true) ? 1 : 0 : (val.toString() === "true") ? 1 : 0 : null;
}
