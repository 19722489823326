import * as Types from '../types/Data';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isUnlock: false,
        unlockField: "",
        isExport: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    masterLeadTypes: {},
    preForeClosureList: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    preForeClosureDetail: {},
    preForeClosureMaster: {}
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.DATA_MASTER_LEAD_TYPE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DATA_MASTER_LEAD_TYPE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                masterLeadTypes: action.masterLeadTypes,
                statusAction: initialState.statusAction
            };
        case Types.DATA_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DATA_MASTER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                preForeClosureMaster: action.preForeClosureMaster,
                statusAction: initialState.statusAction
            };
        case Types.DATA_PRE_FORE_CLOSURE_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DATA_PRE_FORE_CLOSURE_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                preForeClosureList: getResult(action.preForeClosureList),
                statusAction: initialState.statusAction
            };
        case Types.DATA_PRE_FORE_CLOSURE_DETAIL_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DATA_PRE_FORE_CLOSURE_DETAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                preForeClosureDetail: action.preForeClosureDetail,
                statusAction: initialState.statusAction
            };
        case Types.DATA_PRE_FORE_CLOSURE_UPDATE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.DATA_PRE_FORE_CLOSURE_UPDATE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.DATA_PRE_FORE_CLOSURE_DELETE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DATA_PRE_FORE_CLOSURE_DELETE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DATA_PRE_FORE_CLOSURE_FAVORITE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DATA_PRE_FORE_CLOSURE_FAVORITE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DATA_PRE_FORE_CLOSURE_FAVORITE_DELETE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.DATA_PRE_FORE_CLOSURE_FAVORITE_DELETE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.DATA_PRE_FORE_CLOSURE_FAVORITE_EXPORT_ACTION:
            return {...state, isFetching: true, statusAction: {...initialState.statusAction, isExport: true}};
        case Types.DATA_PRE_FORE_CLOSURE_FAVORITE_EXPORT_SUCCESS:
            return {...state, isFetching: false, statusAction: {...initialState.statusAction, isExport: false}};
        case Types.DATA_PRE_FORE_CLOSURE_UNLOCK_ACTION:
            return {
                ...state,
                statusAction: {...initialState.statusAction, isUnlock: true, unlockField: action.params.field}
            };
        case Types.DATA_PRE_FORE_CLOSURE_UNLOCK_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isUnlock: false, unlockField: ""}};
        case Types.DATA_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.DATA_INIT:
            return {
                ...state,
                preForeClosureList: action.params?.clearDetail ? state.preForeClosureList : initialState.preForeClosureList,
                preForeClosureDetail: action.params?.clearDetail ? initialState.preForeClosureDetail : state.preForeClosureDetail,
                statusAction: initialState.statusAction
            };
        default:
            return state;
    }
}
