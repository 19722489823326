import * as Types from '../types/TaxSale';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isUnlock: false,
        unlockField: "",
        isExport: false
    },
    all: [],
    errors: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        pagination: {
            page_size: PAGINATION.page_size,
            page_index: PAGINATION.page_index,
            total: PAGINATION.total,
            total_page: PAGINATION.total_page,
        }
    },
    detail: {},
    master: {}
};

const getResult = (page) => {
    return {
        result: page.result || [],
        pagination: {
            page_size: page?.page_size || PAGINATION.page_size,
            page_index: page?.page_index || PAGINATION.page_index,
            total: page?.total || PAGINATION.total,
            total_page: page?.total_page || PAGINATION.total_page,
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.TAX_SALE_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TAX_SALE_MASTER_SUCCESS:
            return {...state, isFetching: false, master: action.master, statusAction: initialState.statusAction};
        case Types.TAX_SALE_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TAX_SALE_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: getResult(action.taxSale),
                statusAction: initialState.statusAction
            };
        case Types.TAX_SALE_DETAIL_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TAX_SALE_DETAIL_SUCCESS:
            return {...state, isFetching: false, detail: action.taxSaleDetail, statusAction: initialState.statusAction};
        case Types.TAX_SALE_UPDATE_ACTION:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: true}};
        case Types.TAX_SALE_UPDATE_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isSubmit: false}};
        case Types.TAX_SALE_DELETE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TAX_SALE_DELETE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.TAX_SALE_FAVORITE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TAX_SALE_FAVORITE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.TAX_SALE_FAVORITE_DELETE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.TAX_SALE_FAVORITE_DELETE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.TAX_SALE_FAVORITE_EXPORT_ACTION:
            return {...state, isFetching: true, statusAction: {...initialState.statusAction, isExport: true}};
        case Types.TAX_SALE_FAVORITE_EXPORT_SUCCESS:
            return {...state, isFetching: false, statusAction: {...initialState.statusAction, isExport: false}};
        case Types.TAX_SALE_UNLOCK_ACTION:
            return {
                ...state,
                statusAction: {...initialState.statusAction, isUnlock: true, unlockField: action.params.field}
            };
        case Types.TAX_SALE_UNLOCK_SUCCESS:
            return {...state, statusAction: {...initialState.statusAction, isUnlock: false, unlockField: ""}};
        case Types.TAX_SALE_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.TAX_SALE_INIT:
            return {
                ...state,
                data: action.params?.clearDetail ? state.data : initialState.data,
                detail: action.params?.clearDetail ? initialState.detail : state.detail,
                statusAction: initialState.statusAction
            };
        default:
            return state;
    }
}
